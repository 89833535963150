<template>
  <CCol sm="6" lg="4">
    <CCard color="pastelblue" body-wrapper text-color="white" class="card-event">
      <CRow>
        <CCol col="12">
          <h5><b id="text-cut" >COURSE SANDBOX </b><b><a v-on:click="showSandboxDetailModal = true"><i class="far fa-question-circle" ></i></a></b></h5>
        </CCol>
        <CCol col="12" style="font-size: 16px;">
          <CBadge v-if="sandboxIsCreated" color="success">
            เปิดใช้งานเรียบร้อยแล้ว
          </CBadge>
          <CBadge v-else color="secondary">
            ยังไม่ได้เปิดใช้งาน
          </CBadge>
          
          <a v-on:click="manualSync" v-if="studentEnrollStatus === 'failed' || studentEnrollStatus === 'retrying'">
            <CBadge color="dark"><CIcon name="cil-sync"/>try again</CBadge>
          </a>
        </CCol>
        <!-- <CCol col="12">
          <p><small><em>
            คอร์สตัวอย่างที่มีให้อาจารย์สามารถทดลองใช้งานฟังก์ชั่นต่างๆของ LMS ได้
          </em></small></p>
        </CCol> -->
      </CRow>
      <CRow v-if="sandboxIsCreated">
        <CCol col="12" class="text-right" style="bottom: 25px;right:15px;z-index: 999; position:absolute">
          <CButton @click="newtab(`${examLink}course/view.php?id=${sandboxData.course_id}`)" color="customblue2" style="border: 1px solid #ffffff;">Go to course<CIcon name="cil-chevron-right"/></CButton>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol col="12" class="text-right" style="bottom: 25px;right:15px;z-index: 999; position:absolute">
          <a class="text-white" v-on:click="showSandboxDetailModal = true">Course Sandbox คืออะไร คลิกที่นี่</a>{{" "}}
          <CButton @click="onConfirmBeforeOpenSandbox" color="dark" style="border: 1px solid #ffffff;"><i class="fas fa-lock"></i> ใช้งาน Sandbox</CButton>
        </CCol>
      </CRow>
    </CCard>
    <CModal
      title="Course Sand Box คืออะไร?"
      :show="showSandboxDetailModal"
      size="md"
      @update:show="showSandboxDetailModal = false"
    >
      <p style="font-size: 20px;text-indent: 3ch;">
        Coures Sandbox คือ คอร์สที่มีไว้เพื่อให้อาจารย์ได้ทดลองเล่นหรือทดลองใช้งาน LMS โดยที่ Course Sandbox จะไม่ผูกกับรหัสวิชาใดๆ ทำให้อาจารย์สามารถออกแบบรูปแบบข้อสอบหรือลองใช้งานฟังก์ชั่นต่างๆโดยที่ไม่ต้องกลัวว่าจะมีนักศึกษามาเห็นข้อสอบหรือแบบฝึกหัด
      </p>
      <div class="text-center">
        <a href="https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1" target="_blank">
          <CButton color="customblue2"><i class="far fa-comments"></i> สอบถามเพิ่มเติมเกี่ยวกับ Course Sandbox</CButton>
        </a>
      </div>
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>
  </CCol>
</template>

<script>
import Swal from 'sweetalert2'
import Api from '@/services/Api'

export default {
  name: 'CardsExamSandbox',
  props:{
    examObject: Object
  },
  data(){
    return {
        eventName: "",
        examLink:process.env.VUE_APP_EXAM_URL,
        canvasLink:process.env.VUE_APP_CANVAS_URL,
        studentEnrollStatus: "กำลังนำเข้านักศึกษา 35%",
        studentEnrollStatusLatestUpdate: "ไม่ทราบ",
        intervalStatus: "",
        showSandboxDetailModal: false,
        sandboxIsCreated: false,
        sandboxData: {}
    }
  },
  created() {
    console.log("From Card Exam:",this.examObject)
    this.checkSandboxCreation()
  },
  methods: {
    onConfirmBeforeOpenSandbox () {
      Swal.fire({
        title: 'Are you sure?',
        text: "คุณต้องการเปิดใช้งาน Course Sandbox ใช่หรือไม่!",
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        denyButtonColor: '#3085d6',
        confirmButtonColor: '#27a844',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        denyButtonText: 'Sandbox คืออะไร?'
      }).then( async (result) => {
        if (result.isConfirmed) {
          const reqSandbox = {
            "email": localStorage.getItem('email'),
            "lms_type": "exam"
          }
          Swal.fire({
            text: 'กำลังดำเนินการ...',
            allowOutsideClick: false
          })
          Swal.showLoading()
          const resCreateSandbox = await Api.creatSandbox(reqSandbox)
          if(resCreateSandbox.status){
            Swal.fire(
              'เสร็จสิ้น!',
              'ท่านได้ทำการสร้าง Course Sandbox เรียบร้อยแล้ว',
              'success'
            )
            this.checkSandboxCreation()
          }else{
            switch (resCreateSandbox.errorcode) {
              case "shortnametaken":
                Swal.fire(
                  'เกิดข้อผิดพลาด',
                  'ท่านมี Course Sandbox อยู่แล้ว',
                  'error'
                )
                break;
              default:
                Swal.fire(
                  'เกิดข้อผิดพลาด',
                  resCreateSandbox.message,
                  'error'
                )
                break;
            }
          }
        } else if (result.isDenied) {
          this.showSandboxDetailModal = true
        }
      })
    },
    async checkSandboxCreation () {
      const resCheckSandbox = await Api.checkSandboxCreated("exam", localStorage.getItem("email"))
      console.log("resCheckSandbox:",resCheckSandbox)
      if(resCheckSandbox.isFound){
        this.sandboxIsCreated = true
        this.sandboxData = resCheckSandbox
      }
    },
    getStatusBadge (status) {
      switch (status) {
        case 'executing': return 'primary'
        case 'queued': return 'primary'
        case 'retrying': return 'primary'
        case 'failed': return 'danger'
        case 'finished': return 'success'
        default: return 'secondary'
      }
    },
    getStatusText (status) {
      switch (status) {
        case 'executing': return 'กำลังนำเข้านักศึกษา'
        case 'queued': return 'กำลังรอเพื่อนำเข้านักศึกษา'
        case 'retrying': return 'กำลังนำเข้านักศึกษา'
        // case 'retrying': return 'เกิดข้อผิดพลาดกำลังดำเนินการแก้ไข'
        case 'failed': return 'เกิดข้อผิดพลาด'
        case 'finished': return 'นำเข้านักศึกษาสำเร็จ'
        default: return 'ไม่ทราบสถานะ'
      }
    },
    btnClicked (index) {
        console.log("sdfsdf")
        this.$router.push({path: `events/eventdetail?id=${index}`})
    },
    newtab(url){
      var win = window.open(url, '_blank');
      win.focus();
    },
    async timeThaiFormat (timestamp) {
      return new Date(timestamp).toLocaleString('th-TH')
    },
  }
}
</script>
