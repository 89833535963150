<template>
  <div>
    <CRow class="pt--2">
        <CCol class="" col="6">
            <CButton size="lg" @click="openCreateNewExam = true" color="customblue2">สร้างคอร์สใหม่</CButton>&nbsp;
            <CButton size="lg" @click="openUseOldCourseExam = true" color="outline-customblue2">ใช้งานคอร์สเดิม</CButton>
        </CCol>
        <CCol class="text-right" col="6">
          <popper
            trigger="clickToToggle"
            :options="{
              placement: 'bottom',
              modifiers: { offset: { offset: '0,0px' } }
            }">
            <div class="popper text-center" style="padding: 10px;">
              <CRow>
                <CCol col=3>
                  <b>แสดงเทอม</b>
                </CCol>
                <CCol col=9>
                  <CInputCheckbox
                    label="เทอมปัจจุบัน"
                    value="showPresentCheckBox"
                    :inline="true"
                    :checked="filterChecked.present"
                    @update:checked="checkFilter"
                  />
                  <CInputCheckbox
                    label="เทอมถัดไป"
                    value="showFutureCheckBox"
                    :inline="true"
                    :checked="filterChecked.future"
                    @update:checked="checkFilter"
                  />
                </CCol>
              </CRow>
              
              
            </div>
        
            <button class="btn btn-secondary btn-lg" slot="reference">
              <b>Filter<CIcon height=25 name="cil-filter"/></b>
            </button>
          </popper>
        </CCol>
    </CRow>
    <CreateNewExam  v-if="renderComponent" :openModal="openCreateNewExam" @afterCloseModal="openCreateNewExam = false" @afterCreateExam="callExamListAgain" />
    <UseOldCourseExam  v-if="renderComponent" :openModal="openUseOldCourseExam" @afterCloseModal="openUseOldCourseExam = false" @afterCreateExam="callExamListAgain" />
    <CRow>
        <CardsExamSandbox :examObject="examList[0]" @afterDeleteEvent="callExamListAgain"/>
        <CardsExam v-bind:key="exam.id"  v-for='exam in examList' :examObject="exam" @afterDeleteEvent="callExamListAgain"/>
    </CRow>
    <CCard>
    </CCard>
  </div>
</template>

<script>
import CardsExam from './CardsExam'
import CardsExamSandbox from './CardsExamSandbox'
import CreateNewExam from './CreateNewExam'
import UseOldCourseExam from './UseOldCourseExam'
import ExamApi from "@/services/ExamApi"
import Swal from 'sweetalert2'
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  name: 'Examlist',
  components: {
    CardsExam,
    CardsExamSandbox,
    CreateNewExam,
    UseOldCourseExam,
    'popper': Popper
  },
  data () {
    return {
      renderComponent: true,
      examListAll:[],
      examList:[],
      openCreateNewExam:false,
      openUseOldCourseExam:false,
      reg_Term:process.env.VUE_APP_REG_TERM,
      reg_Year:process.env.VUE_APP_REG_YEAR,
      filterChecked:{
        all:false,
        past:false,
        present:true,
        future:false
      },
      term:{
        presentTerm: "",
        futureTerm: "",
        pastYearForFuterTerm: "",
      }
    }
  },
  created() {
    this.calculateTerm()
    this.getExamListByUser(localStorage.getItem('email'))
  },
  methods: {
    btnClicked (index) {
      this.$router.push({path: `events/${index}`})
    },
    calculateTerm () {
      this.term.presentTerm = `${this.reg_Term}/${this.reg_Year.slice(2, 4)}`
      this.reg_Term = parseInt(this.reg_Term)
      if(this.reg_Term < 3){
          this.term.futureTerm = `${this.reg_Term+1}/${this.reg_Year.slice(2, 4)}`
          this.term.pastYearForFuterTerm = `${this.reg_Term+1}/${parseInt(this.reg_Year.slice(2, 4))-1}`
      }else{
          this.term.futureTerm = `1/${parseInt(this.reg_Year.slice(2, 4))+1}`
          this.term.pastYearForFuterTerm = `1/${parseInt(this.reg_Year.slice(2, 4))}`
      }
    },
    async callExamListAgain() {
      console.log('call again')
      this.openCreateNewExam = false
      this.openUseOldCourseExam = false
      await this.getExamListByUser(localStorage.getItem('email'))
    },
    async getExamListByUser(email){
      console.log("getExamListByUser")
      Swal.fire({
        text: 'กำลังโหลด...',
        allowOutsideClick: false
      })
      Swal.showLoading()
      let examList = await ExamApi.getExamListByUser(email)
      console.log("getExamListByUser",examList)
      if(examList){
        this.examListAll = examList
        this.examList = await this.filterOnlyThisTerm(examList, this.reg_Term, this.reg_Year)
        if(localStorage.getItem('filterShowFuture') == 'true'){
          this.filterChecked.future = true
          let val = {target:{value:""}}
          val.target.value = "showFutureCheckBox"
          this.checkFilter (true, val)
        }
        if(localStorage.getItem('filterShowPast') == 'true'){
          this.filterChecked.past = true
        }
        console.log("getExamListByUser:",examList)
        Swal.close()
        // this.getEnrollStatus(email)
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    async filterOnlyThisTerm (examListAll, term, year) {
      let newExamList = []
      for(let i = examListAll.length - 1; i >= 0; i--){
        // console.log("filterOnlyThisTerm:",examListAll[i])
        // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
        if(examListAll[i].idnumber.slice(0, 3) === term+year.slice(2, 4)){
          // console.log(examListAll[i].idnumber.slice(0, 3),term+year.slice(2, 4))
          // console.log(examListAll[i].idnumber.slice(0, 3) !== term+year.slice(2, 4))
          // examListAll.splice(i, 1)
          newExamList.push(examListAll[i])
        }
      }
      return newExamList
    },
    async deleteOnlyThisTerm (examList, term, year) {
      // let newExamList = []
      for(let i = examList.length - 1; i >= 0; i--){
        console.log("filterOnlyThisTerm:",examList[i])
        // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
        if(examList[i].idnumber.slice(0, 3) === term+year.slice(2, 4)){
          console.log(examList[i].idnumber.slice(0, 3),term+year.slice(2, 4))
          console.log(examList[i].idnumber.slice(0, 3) !== term+year.slice(2, 4))
          examList.splice(i, 1)
          // newExamList.push(examList[i])
        }
      }
      return examList
    },
    async getEnrollStatus (email) {
      let getEnrollStatus = await ExamApi.getEnrollStatus(email)
      if(getEnrollStatus){
        console.log("getEnrollStatus:",getEnrollStatus)
        this.filterEnrolStatus(getEnrollStatus)
      }
    },
    async filterEnrolStatus (enrollStatusArr) {
      let inArr = []
      for(let i=0;i<enrollStatusArr.length;i++){
        let index = inArr.findIndex(x => x.course_id === enrollStatusArr[i].course_id);
        console.log(index)
        if(index < 0){
          inArr.push(enrollStatusArr[i])
        }else{
          console.log('Duplicate')
          if(parseInt(inArr[index].job_status_id) < parseInt(enrollStatusArr[i].job_status_id)){
            console.log('New')
            inArr.splice(index, 1);
            inArr.push(enrollStatusArr[i])
          }
        }
      }
      console.log(inArr)
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    async checkFilter (event, val) {
      let examListAfterAppend = []
      let examListAfterDelete = []
      let splitTerm = []
      if(event){
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("ExamListAll:",this.examListAll)
            localStorage.setItem('filterShowFuture', true)
            splitTerm = this.term.futureTerm.split("/")
            examListAfterAppend = await this.filterOnlyThisTerm(this.examListAll, splitTerm[0], "25"+splitTerm[1])
            this.examList = this.examList.concat(examListAfterAppend)
            break
          case "showPresentCheckBox":
            console.log("ExamListAll:",this.examListAll)
            localStorage.setItem('filterShowPresent', true)
            splitTerm = this.term.presentTerm.split("/")
            examListAfterAppend = await this.filterOnlyThisTerm(this.examListAll, splitTerm[0], "25"+splitTerm[1])
            this.examList = this.examList.concat(examListAfterAppend)
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }else{
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("ExamList:",this.examList)
            localStorage.setItem('filterShowFuture', false)
            splitTerm = this.term.futureTerm.split("/");
            examListAfterDelete = await this.deleteOnlyThisTerm(this.examList, splitTerm[0], "25"+splitTerm[1])
            this.examList = examListAfterDelete
            break
          case "showPresentCheckBox":
            console.log("ExamList:",this.examList)
            localStorage.setItem('filterShowPresent', false)
            splitTerm = this.term.presentTerm.split("/");
            examListAfterDelete = await this.deleteOnlyThisTerm(this.examList, splitTerm[0], "25"+splitTerm[1])
            this.examList = examListAfterDelete
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }
    }
  }
}
</script>
